<div class="row common-styles">
    <div class="col-lg-10">
        <div class="d-flex  photo-album-buttonbar"
        [ngClass]="{'justify-content-between' : !userAvailable,
             'justify-content-center' : userAvailable}"
        >
            <!-- Heading -->
            <div class="row">
                <div class="col-lg-4 photo-lg-4">
                    <img src="../../../assets/Indietown_icons/photo-album-img.svg" alt="">
                </div>
                <div class="col-lg-8 p-0 photo-lg-8">
                    <h1 class="photoalbumtitle" style="width: 137px;">Photo Album</h1>
                </div>
            </div>

            <!-- Actions -->
            <div class="plus-right-img"  *ngIf="!userAvailable">
                    <div *ngIf="!photoAlbumCheckbox" class="d-inline-block"><img src="../../../assets/Indietown_icons/photo-album-right.svg" style="    margin-top: -1px; width: 23px; height: 22px;" (click)="showCheckbox()" alt="" class="album-bar-images"></div>
                    <div *ngIf="photoAlbumCheckbox" (click)="onClickCancleImage()" class="d-inline-block"><img src="../../../assets/Indietown_icons/photo-album-close.svg" alt="" class="album-bar-images"></div>
                    <div class="d-inline-block"><img src="../../../assets/Indietown_icons/photo-album-plus.svg" alt="" style="margin-left: 18px; width: 23px; height: 22px;
                        width: 21.5px;
                        height: 21.5px;" (click)="myFile2.click()"></div>
            </div>
        </div>

        <div class="mt-3">
            <div class="file-input-wrapper-album" *ngIf="userAvailable==false && !slidesStore.length">
                <input type="hidden" name="" id="">

                <div>
                    <label (click)="myFile2.click()" class="btn btn-red photoalbumplaceholder" style="color:white !important;padding: 0;
                    margin-left: -24px;">
                        <img [src]="uploadPhotoAlbumUrl" alt=""
                        style="width: 184px;width: 184px; border-radius: 11px;" id="imageView">
                    </label>
                    
                </div>
            </div>
            <input #myFile2 type="file" name="myFile2" id="myFile2" value="" (change)="uploadPhotoAlbum($event)"
            accept="image/*" style="display: none;" multiple/>
            <!-- height: 800px -->
            <div class="d-flex flex-wrap no-scroll" infinite-scroll [scrollWindow]="false"
            (scrolled)="getMorePhotosOfAlbum($event)" [infiniteScrollDistance]="2" infinite-scroll-disabled="!scrollForPhotos" style="max-height: 800px;overflow-y: auto;">
                <div class="position-relative photoalbumimg" style="width: 150px;height: 150px;" *ngFor="let i of slidesStore;let index=index">
                    <div  class="position-absolute" *ngIf="photoAlbumCheckbox" style="right: 12px;top: 12px;">
                        <!-- <input type="checkbox" style="height: 25px;width: 25px;position: relative;left: 258px;top: 11px;"> -->
                        <div class="checkboxtxt">
                            <mat-checkbox (change)="onClickCheckbox($event,i?._id,index)" class="checboxtxt" style=""></mat-checkbox>
                        </div>
                        
                    </div>
                    <!-- height="200" width="200" -->
                    <img [src]="i?.fileUrl" alt=""  style="cursor: pointer; border-radius: 15px;object-fit: cover;width: 100%;height: 100%;" (click)="openSlider(i?._id)">
                </div>
                <div *ngIf="!slidesStore.length && userAvailable" style="text-align: center; font-size: 20px; font-weight: bold; margin-top: 20px;
                width: -webkit-fill-available;">
                    No Photos Uploaded Yet!
                </div>
            </div>
        </div>
    </div>
</div>
          
        

<div style="display: flex;justify-content: space-between;" class="selectedItems" *ngIf="deletePhotosIds.length>0">
    <h1>{{deletePhotosIds.length == 1 ? deletePhotosIds.length + " Photo" : deletePhotosIds.length + " Photos"}} Selected</h1>
    <div>
        <img src="../../../assets/Indietown_icons/delete.svg" alt="" (click)="deleteMultiplePhotos()" style="padding-right: 79px; cursor: pointer;">
    </div>
</div>


<ng-template #deleteAlbumImages>
    <div class="modal-header photoheader" style="padding: 30px 30px 0;">
        <button type="button" style="outline: 0;" class="close pull-right" aria-label="Close" (click)="deletePhotosIds?.length ? modalRef.hide() : this.deleteAlbumModalRef.hide()">
            <span aria-hidden="true"><img src="../../../../../assets/Indietown_icons/close.svg" alt=""></span>
        </button>
    </div>
    <div class="modal-body text-center delete-modal align-items-center">
        <!-- <img src="../../../../../assets/images/create-successfull-img.svg" alt=""> -->
        <input type="hidden" id="deletePhotoAlbumId">
        <p style="margin-top: 0px;font-size: 22px;font-weight: 500;line-height: 40px;">Are you sure want to delete <br> this photo?</p>
        <div class="modal-btn d-flex justify-content-around" style="margin-top: 39px;">
            <button class="btn btn-blue notDeleteAlbum" (click)="photoDelete()">Yes</button>
            <button class="btn btn-red okDeleteAlbum" style="margin-right: 14.5px;" (click)="cancel()">No</button>
        
        </div>
    </div>
</ng-template>


<!-- <div>Some tags before</div>
<owl-carousel-o [options]="customOptions2" #owlCar>
  <ng-template carouselSlide> <img
    src="https://d1hbsedo7kzk7w.cloudfront.net/file_1681906516984.jpg"
    alt="1"
    title="title1"
  /></ng-template>  
  <ng-template carouselSlide dataMerge="3"><img
    src="https://d1hbsedo7kzk7w.cloudfront.net/file_1681906528498.jpg"
    alt="2"
    title="title2"
  /></ng-template>  
  <ng-template carouselSlide><img
    src="https://d1hbsedo7kzk7w.cloudfront.net/file_1681981206408.jpg "
    alt="3"
    title="title3"
  /></ng-template>  
  <ng-template carouselSlide><img
    src="https://d1hbsedo7kzk7w.cloudfront.net/file_1681906603291.jpg"
    alt="4"
    title="title4"
  /></ng-template> 
</owl-carousel-o>
<div>Some tags after</div> -->


<!-- photoAlbumSlider -->
<!-- <ng-template #photoAlbumSlider>
    <div class="modal-header">
        <div class="d-flex justify-content-between" >
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()" style="margin-left: 0px;">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="text-center" >
                <h1>Photo Album</h1>
            </div>
            <button type="button" class="close pull-right photoAlbumDelete" aria-label="Close" (click)="onClickDeletePhotoAlbum(1)" *ngIf="deleteButton">
                <span aria-hidden="true">
                        <img src="../../../assets/Indietown_icons/delete.svg" alt="">
                </span>
            </button>
        </div>
    </div>
    <div class="modal-body">
            <owl-carousel-o [options]="customOptions2" (translated)="getActiveSlideData($event)" >
                <ng-container *ngFor="let slide of slidesStore;let index=index">
                    <ng-template carouselSlide  [id]="slide._id">
                        <img [src]="slide.fileUrl" [alt]="slide.fileUrl" [title]="slide.fileUrl"> 
                    </ng-template>
                </ng-container>
            </owl-carousel-o>
    </div>
</ng-template> -->


